'use client';

import { useCallback,useState, useTransition } from 'react';

import { QueryParams } from '@/lib/utils';

import { cachedFetchAction,fetchAction } from './action';

export type FetchOptions = Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown>;
  searchParams?: QueryParams;
  memoryCache?: boolean;
};

export async function fetchOnClient<T>({
  url, options = {}, onSuccess, onError
}: {url: string, options?: FetchOptions, onSuccess?: (data: T) => void; onError?: (e: Error) => void}) {
  try {

    const {memoryCache, ...rest} = options;

    const {data, error} = await (memoryCache ? cachedFetchAction : fetchAction)<T>(url, rest);

    if (error) throw new Error(error);
    onSuccess?.(data);
    return {data: data, error: null};
  } catch (e) {
    onError?.(e as Error);
    throw new Error((e as Error).message);
  }
}
export function useFetch<T>(): [
  (url: string, options?: FetchOptions) => Promise<{ data: T | null; error: unknown }>, 
  { data: T | null; error?: Error | null; loading: boolean }
  ] {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();

  const fetchData = useCallback(async (url: string, options: FetchOptions = {}) => {
    setLoading(true);
    try {
      const { data: d, error: e } = await fetchOnClient<T>({ url, options });

      startTransition(() => {
        setData(d);
        setError(null);
      });

      if(e) throw new Error(e);
      return { data: d, error: e };
    } catch (e) {
      startTransition(() => {
        setError(e as Error);
        setData(null);
      });

      throw new Error((e as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  return [fetchData, { data, error, loading: loading || isPending }];
}