'use client';

import { useEffect, useRef, useState } from 'react';


interface UseAudioPlayerProps {
  src?: string;
  skipDuration?: number;
  replayDuration?: number;
}

export const useAudioPlayer = ({ src, skipDuration = 15, replayDuration = 15 }: UseAudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [bufferedPercentage, setBufferedPercentage] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => setCurrentTime(audio?.currentTime ?? 0);
    const updateDuration = () => setDuration(audio?.duration ?? 0);
    const updateBufferedPercentage = () => {
      const buffered = audio?.buffered;
      if (buffered?.length) {
        const end = buffered.end(buffered.length - 1);
        const percentage = (end / (audio?.duration ?? 1)) * 100;
        setBufferedPercentage(percentage);
      }
    };
    const updatePlay = () => setIsPlaying(true);
    const updatePause = () => setIsPlaying(false);

    if (audio) {
      audio.addEventListener('timeupdate', updateCurrentTime);
      audio.addEventListener('durationchange', updateDuration);
      audio.addEventListener('progress', updateBufferedPercentage);
      audio.addEventListener('play', updatePlay);
      audio.addEventListener('pause', updatePause);
      audio.addEventListener('ended', updatePause);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', updateCurrentTime);
        audio.removeEventListener('durationchange', updateDuration);
        audio.removeEventListener('progress', updateBufferedPercentage);
        audio.removeEventListener('play', updatePause);
        audio.removeEventListener('pause', updatePause);
        audio.removeEventListener('ended', updatePause);
      }
    };
  }, []);

  useEffect(() => {
    if(audioRef.current) {
      if(src) {
        audioRef.current.src = src
      } else {
        audioRef.current.pause();
        audioRef.current.removeAttribute('src');
        audioRef.current.load();
      }
    }
  }, [src])

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio) setTimeout(() => audio[isPlaying ? "pause" : "play"](), 200);
  };

  const handleForward = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime += skipDuration;
    }
  };

  const handleBackward = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime -= replayDuration;
    }
  };

  const handleCurrentTime = (d: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = d;
    }
  };

  return {
    audioRef,
    isPlaying,
    currentTime,
    duration,
    bufferedPercentage,
    togglePlayPause,
    handleForward,
    handleBackward,
    handleCurrentTime
  };
};
