"use client";

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/navigation";

import { useFetch } from "@/hooks/useFetch/useFetch";
import { Profile } from "@/lib/schema";
import { getAuthToken, removeAuthToken } from "@/lib/utils";

const initialValues = {
  isAuth: false,
  logout: () => false,
  login: () => false,
  me: undefined,
  loadingMe: true,
  fetchMe: async () => ({ data: null, error: null }),
};

type AuthContextType = {
  isAuth: boolean;
  loadingMe: boolean;
  login: () => void;
  logout: () => void;
  me?: Profile;
  fetchMe: () => Promise<{
    data: { data: Profile } | null;
    error: unknown;
  }>;
};

const AuthContext = createContext<AuthContextType>(initialValues);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const route = useRouter();
  const [isAuth, setIsAuth] = useState(!!getAuthToken());
  const [fetchData, { data, loading }] = useFetch<{ data: Profile }>();

  useEffect(() => {
    if (isAuth && !data?.data)
      fetchData("/users/info", { cache: "no-store", memoryCache: true });
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        login: () => {
          route.refresh();
          fetchData("/users/info", { cache: "no-store", memoryCache: true });
          setIsAuth(true);
        },
        logout: () => {
          setIsAuth(false);
          removeAuthToken();
          route.refresh();
        },
        loadingMe: loading,
        me: data?.data,
        fetchMe: () =>
          fetchData("/users/info", { cache: "no-store", memoryCache: true }),
        isAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
