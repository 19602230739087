"use client";

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { X } from "lucide-react";
import Image from "next/image";

import ForwardIcon from "@/assets/icons/forward-15.svg";
import PauseIcon from "@/assets/icons/pause.svg";
import PlayIcon from "@/assets/icons/play.svg";
import BackwardIcon from "@/assets/icons/replay-5.svg";
import { AudioSlider } from "@/components/partials/audio-slider";
import { useAudioPlayer } from "@/hooks/useAudioPlayer";
import { AUDIO_DURATION_LIST } from "@/lib/constants";
import { ID } from "@/lib/schema";
import { apiImage, cn, formatDuration, storage } from "@/lib/utils";

import { useAuth } from "./auth";

export enum AudioTypeEnum {
  "PODCAST" = "PODCAST",
  "TRACK" = "TRACK",
  "INTRO" = "INTRO",
  "BOOK" = "BOOK",
  "MOOD" = "MOOD",
}

export type AudioInfoType = {
  id: `${AudioTypeEnum}.${ID}`;
  src: string;
  thumbnail: string;
  title: string;
};

export type AudioPlayerValueType = ReturnType<typeof useAudioPlayer> & {
  setAudio: (audio?: AudioInfoType, openDrawer?: boolean) => void;
  audio?: AudioInfoType;
  formatTime: (duration: number) => string;
  toggleAudioDrawer: (open: boolean) => void;
};

const AudioPlayerContext = createContext<AudioPlayerValueType>({
  setAudio: () => false,
  audioRef: { current: null },
  audio: undefined,
  isPlaying: false,
  currentTime: 0,
  duration: 1,
  togglePlayPause: () => false,
  handleForward: () => false,
  handleBackward: () => false,
  toggleAudioDrawer: () => false,
  handleCurrentTime: () => false,
  formatTime: () => "",
  bufferedPercentage: 0,
});

export const useAudioContext = () => useContext(AudioPlayerContext);

export const AudioPlayerProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth();
  const [audio, setAudio] = useState<AudioInfoType | undefined>();
  const {
    audioRef,
    isPlaying,
    currentTime,
    duration,
    togglePlayPause,
    handleForward,
    handleBackward,
    bufferedPercentage,
    handleCurrentTime,
  } = useAudioPlayer({ src: audio?.src });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAuth) {
      setAudio(undefined);
      setOpen(false);
    }
    return () => {
      setAudio(undefined);
      setOpen(false);
    };
  }, [isAuth]);

  useEffect(() => {
    const ar = audioRef.current;
    if (audio && ar) {
      const inProgress = (storage.get<
        Record<
          AudioInfoType["id"],
          {
            currentTime: number;
            duration: number;
          }
        >
      >(AUDIO_DURATION_LIST) || {})[audio.id];

      if (inProgress) handleCurrentTime(inProgress.currentTime);
    }
  }, [audio, audioRef?.current]);

  const formatTime = useCallback(formatDuration, []);
  const saveProcess = () => {
    if (!audio?.id) return;
    storage.set<Record<ID, { currentTime: number; duration: number }>>(
      AUDIO_DURATION_LIST,
      (prev, setter) =>
        setter({
          ...(prev || {}),
          [audio.id]: { currentTime, duration },
        })
    );
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        setAudio: (v, o = true) => {
          saveProcess();
          setOpen(o);
          setAudio(v);
        },
        toggleAudioDrawer: (v: boolean) => {
          setOpen(v);
          togglePlayPause();
        },
        audioRef,
        isPlaying,
        currentTime,
        duration,
        togglePlayPause: () => {
          saveProcess();
          togglePlayPause();
        },
        handleForward,
        handleBackward,
        bufferedPercentage,
        formatTime,
        audio,
        handleCurrentTime,
      }}
    >
      {children}
      <audio
        ref={audioRef}
        autoPlay
        aria-label="Fixed to bottom audio player"
      />
      <div
        className={cn(
          "fixed left-0 bg-[#FBF9F8] z-50 w-full bottom-0 transition-transform",
          {
            "translate-y-full": !open,
            "translate-y-0": open,
          }
        )}
      >
        <div className="w-[690px] max-w-[95%] mx-auto py-4 flex items-center gap-4 max-sm:gap-2 max-sm:py-2 relative">
          {!!audio?.thumbnail && (
            <div className="rounded-sm w-12 h-12 relative md:w-[60px] md:h-[60px] overflow-hidden">
              <Image
                src={apiImage(audio?.thumbnail, "small")}
                fill
                alt=""
                className="object-cover"
              />
            </div>
          )}
          <div className="flex-1 min-w-0 space-y-2">
            <div className="flex items-center gap-2 justify-between min-w-0">
              <p className="font-bold text-sm truncate">{audio?.title}</p>
              <div className="flex items-center gap-6 max-sm:gap-4">
                <button
                  aria-label="Backward audio by 5 seconds"
                  type="button"
                  className="transition active:-rotate-45 active:scale-95"
                  onClick={handleBackward}
                >
                  <BackwardIcon
                    style={{ width: 24, height: 24 }}
                    className="max-sm:hidden"
                  />
                  <BackwardIcon
                    style={{ width: 20, height: 20 }}
                    className="sm:hidden"
                  />
                </button>
                <button
                  aria-label="Toggle play and pause"
                  type="button"
                  className="transition active:scale-75 w-6 flex items-center justify-center"
                  onClick={togglePlayPause}
                >
                  {isPlaying ? (
                    <>
                      <PauseIcon
                        style={{ width: 22, height: 22 }}
                        className="max-sm:hidden"
                      />
                      <PauseIcon
                        style={{ width: 18, height: 18 }}
                        className="sm:hidden"
                      />
                    </>
                  ) : (
                    <>
                      <PlayIcon
                        style={{ width: 18, height: 18 }}
                        className="max-sm:hidden"
                      />
                      <PlayIcon
                        style={{ width: 14, height: 14 }}
                        className="sm:hidden"
                      />
                    </>
                  )}
                </button>
                <button
                  aria-label="Forward audio by 5 seconds"
                  type="button"
                  className="transition active:rotate-45 active:scale-95"
                  onClick={handleForward}
                >
                  <ForwardIcon
                    style={{ width: 24, height: 24 }}
                    className="max-sm:hidden"
                  />
                  <ForwardIcon
                    style={{ width: 20, height: 20 }}
                    className="sm:hidden"
                  />
                </button>
              </div>
            </div>
            <AudioSlider />
            <div className="flex justify-between text-xs text-[#898480] mt-1">
              <span>{formatTime(currentTime)}</span>
              <span>
                {currentTime >= duration ? "" : "-"}
                {formatTime((duration || 0) - currentTime)}
              </span>
            </div>
          </div>
          {open && (
            <button
              type="button"
              aria-label="close audio player sheet"
              className="bg-[#FBF9F8] absolute -top-8 right-0 rounded-sm p-1"
              onClick={() => {
                saveProcess();
                setOpen(false);
                setAudio(undefined);
              }}
            >
              <X size={20} />
            </button>
          )}
        </div>
      </div>
    </AudioPlayerContext.Provider>
  );
};
