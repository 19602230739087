"use client";

import { useCallback, useEffect, useRef } from "react";

import { useAudioContext } from "@/context";
import { cn } from "@/lib/utils";

import { Slider } from "../ui/slider";

export function AudioSlider({
  size = "md",
  disabled,
}: {
  size?: "md" | "lg";
  disabled?: boolean;
}) {
  const { audioRef, bufferedPercentage, currentTime, duration } =
    useAudioContext();
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const bufferRef = useRef<HTMLDivElement | null>(null);
  const debounceTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (sliderRef.current && !sliderRef.current?.dataset.focused)
      sliderRef.current.value = String(currentTime ?? 0);
  }, [currentTime, sliderRef]);

  const handleSliderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const audio = audioRef.current;
      if (audio) {
        const newTime = parseFloat(event.target.value);

        if (debounceTimeoutRef.current)
          clearTimeout(debounceTimeoutRef.current);
        bufferRef.current!.style.width = `${(newTime / (audio?.duration || 1)) * 100}%`;
        sliderRef.current!.value = String(newTime);
        sliderRef.current!.dataset.focused = "true";

        debounceTimeoutRef.current = window.setTimeout(() => {
          audio.currentTime = newTime;
          sliderRef.current!.removeAttribute("data-focused");
        }, 250);
      }
    },
    [audioRef, bufferRef]
  );

  return (
    <div className="relative overflow-hidden">
      <div
        className={cn("absolute top-0 left-0 bg-slate-200 rounded-full z-[1]", {
          "h-0.5": size === "md",
          "h-1": size === "lg",
        })}
        style={{ width: `${bufferedPercentage}%` }}
      />
      <div
        className={cn(
          "absolute top-0 left-0 bg-primary/40 rounded-full z-[2]",
          {
            "h-0.5": size === "md",
            "h-1": size === "lg",
          }
        )}
        ref={bufferRef}
      />
      <Slider
        disabled={disabled}
        defaultValue={[currentTime]}
        value={[currentTime]}
        max={duration ?? 1}
        step={1}
        trackClassName={cn({
          "h-0.5": size === "md",
          "h-1": size === "lg",
        })}
        thumbClassName="hidden"
        rangeClassName="z-[3]"
      />
      <input
        ref={sliderRef}
        disabled={disabled}
        type="range"
        min="0"
        max={duration || 1}
        onChange={handleSliderChange}
        aria-label="Audio slider range between 0 and total duration"
        className={cn("absolute w-full h-full top-0 z-[4]", {
          "audio-slider h-0.5": size === "md",
          "audio-slider-lg !h-1": size === "lg",
        })}
      />
    </div>
  );
}
