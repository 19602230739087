"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePathname } from "next/navigation";
import { toast } from "sonner";

import { fetchOnClient, FetchOptions } from "@/hooks/useFetch/useFetch";
import { CartItemType, ID } from "@/lib/schema";

import { useAuth } from "./auth";

interface CartContextType {
  items: CartType["cartItems"];
  totalPrice: CartType["totalPrice"];
  addToCart: (item: CartItemType) => Promise<any>;
  removeFromCart: (itemId: ID) => Promise<any>;
  clearCart: () => void;
}

export type CartType = {
  cartItems: (CartItemType & { adding?: boolean; removing?: boolean })[];
  totalPrice: number;
  cartType?: 1 | 0;
};

const CartContext = createContext<CartContextType>({
  items: [],
  totalPrice: 0,
  addToCart: async () => false,
  removeFromCart: async () => false,
  clearCart: () => false,
});

export const useCart = () => useContext(CartContext);

const INITIAL_STATE = { cartItems: [], totalPrice: 0 };

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuth } = useAuth();
  const pathname = usePathname();
  const cartType = pathname.startsWith("/magazine") ? 1 : 0;
  const [cart, setCart] = useState<CartType>({ ...INITIAL_STATE, cartType });

  useEffect(() => {
    if (isAuth) {
      fetchOnClient<{ data: CartType }>({
        url: "/cart",
        options: {
          cache: "no-store",
          searchParams: { cartType },
        },
      })
        .then((c) =>
          setCart(
            Array.isArray(c.data?.data)
              ? INITIAL_STATE
              : { ...(c.data?.data || INITIAL_STATE), cartType }
          )
        )
        .catch(() => setCart(INITIAL_STATE));
    } else {
      setCart(INITIAL_STATE);
    }
  }, [isAuth, pathname]);

  const addToCart = useCallback(
    (item: CartItemType) => {
      if (!isAuth) {
        toast.error("Нэвтрэх шаардлагатай");
        return Promise.reject("Нэвтрэх шаардлагатай");
      }
      setCart((c) => ({
        ...c,
        cartItems: [...c.cartItems, { ...item, adding: true }],
      }));
      return modifyCart({
        options: {
          method: "POST",
          body: {
            productId: item.productId,
            quantity: item.quantity,
            cartType,
          },
        },
        onSuccess: () => toast.success("Амжилттай сагслагдлаа"),
        onError: () => toast.error("Сагсанд нэмэхэд алдаа гарлаа"),
      });
    },
    [isAuth, pathname]
  );

  const removeFromCart = useCallback(
    (itemId: ID) => {
      if (!isAuth) {
        toast.error("Нэвтрэх шаардлагатай");
        return Promise.reject("Нэвтрэх шаардлагатай");
      }
      setCart((c) => ({
        ...c,
        cartItems: c.cartItems.map((cc) => ({
          ...cc,
          removing: cc.productId === itemId,
        })),
      }));
      return modifyCart({
        options: {
          method: "PATCH",
          body: { productId: itemId, quantity: 0, cartType },
        },
        onSuccess: () =>
          toast.success("Сагснаас хасагдлаа", { position: "top-right" }),
        onError: () =>
          toast.error("Сагснаас хасахад алдаа гарлаа", {
            position: "top-right",
          }),
      });
    },
    [isAuth, pathname]
  );

  const modifyCart = useCallback(
    ({
      options = {},
      onSuccess,
      onError,
    }: {
      options?: FetchOptions;
      onSuccess?: () => void;
      onError?: () => void;
    }) =>
      fetchOnClient<
        {
          success: boolean;
          message: string;
        } & { data: CartType }
      >({
        url: "/cart/items",
        options: { cache: "no-store", ...options },
        onError: () => {
          setCart((c) => ({
            // eslint-disable-next-line unused-imports/no-unused-vars
            cartItems: c.cartItems.map(({ adding, removing, ...rest }) => ({
              ...rest,
            })),
            totalPrice: c.totalPrice ?? 0,
            cartType: c.cartType,
          }));
          onError?.();
        },
        onSuccess: (c) => {
          setCart((prev) => ({
            cartItems: c.data?.cartItems ?? [],
            totalPrice: c.data?.totalPrice ?? 0,
            cartType: prev.cartType,
          }));
          onSuccess?.();
        },
      }),
    []
  );

  return (
    <CartContext.Provider
      value={{
        items: cart.cartItems,
        totalPrice: cart.totalPrice,
        clearCart: () => setCart(INITIAL_STATE),
        addToCart,
        removeFromCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
